$(function(){
  if ($('body').hasClass('top')) {
    // スクロールでロゴ非表示
    function displayLogo(){
      var scrollTop = $(window).scrollTop();
      if (scrollTop > 300) {
        // $(".sitename").fadeOut(400);
        $(".sitename").css('opacity','0');
      } else {
        // $(".sitename").fadeIn(200).css('paddingBottom',scrollTop+'px');
        $(".sitename").css({
          'paddingBottom':scrollTop+'px',
          'opacity':'1'
        });
      }
    }
    $(window).on("load scroll", displayLogo);
    //
    // $('.navbar-nav').click(function(){
    //   $('#navbars').removeClass('show');
    // });
    // feature全画面背景
    $(window).on("load resize", function(){
      var w = $(window).width(),
          h = $(window).height();
      if (w > h || w > 1200) {
        $('#feature').backstretch("/uploads/feature.jpg");
      } else {
        // スマホ縦用の画像を表示
        $('#feature').backstretch("/uploads/feature-s.jpg");
      }
    });
  }

  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $(".to-page-top").fadeOut();
    } else {
      $(".to-page-top").fadeIn();
      $(".to-page-top a").smoothScroll({offset: 0});
    }
  }
  $(window).on("load scroll", displayToTop);
  // home
  if ($('body').hasClass('home')) {

    $('.feature').click(function(){
      window.location=$(this).find("a").attr("href");
      return false;
    });
    // instagram
    $('#instagram').imagesLoaded(function(){
      $(".instagram").text("loading...");
      $.ajax({
        url: '/insta.php',
        dataType: 'json',
        error: function(jqXHR, textStatus, errorThrown) {
          $("#instagram .instagram").text(textStatus);
        },
        success: function(data) {
          var insert = '';
          for (var i = 0; i < data.length; i++) {
            insert += '<a href="' + data[i]['link'] + '" target="_blank">';
            insert += '<div class="insta-img" style="background:url(' + data[i]['images']['low_resolution']['url'] + ')no-repeat center center;background-size:cover;width: 100%;padding-bottom: 100%;"></div>';
            insert += '</a>';
          };
          $('#instagram .insta-photos').append(insert);
          $("#instagram .instagram").text("");
        }
      });
    });
    $(window).imagesLoaded();
  }
  if ($('body').hasClass('tsumitori-top')) {
    $('#feature').slick({
      autoplay:true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
});
